import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Table as MUITable } from "@mui/material/";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { visuallyHidden } from "@mui/utils";
import { moduleNameMap } from "../../config/config";

const DEFAULT_ROWS_PERPAGE = 20;

const ellipsisStyle = {
  display: "inline-block",
  width: "9rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow sx={{ bgcolor: "#424242", color: "#FFFFFF" }}>
        <TableCell padding="none" sx={{ color: "currentcolor", width: "42px" }}>
          <Checkbox
            sx={{ color: "currentcolor" }}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={(event) => onSelectAllClick(event)}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              maxWidth: "10%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "currentcolor",
              "& .MuiTableSortLabel-root:hover": {
                color: "currentcolor",
              },
              "& .MuiTableSortLabel-root.Mui-active": {
                color: "currentcolor",
                "& .MuiTableSortLabel-icon": { color: "currentcolor" },
              },
            }}
            key={headCell.id}
            align="left"
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                "& svg": { color: "currentcolor" },
              }}
            >
              <Box as="span" sx={ellipsisStyle}>
                {headCell.label}
              </Box>

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function Table({
  module,
  tablePlaceholderHeight = "100%",
  rows,
  rowstaLoading,
  handleSelectRow,
  handleSelectAllClick,
  selected,
  emailFields,
  onTableClose,
  handleTableBack,
  listViewPage,
  notSelected,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("propertyId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PERPAGE);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected?.indexOf(id) !== -1;
  const isDisabled = (id) => notSelected?.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [rows, order, orderBy, page, rowsPerPage]
  );

  const REMAINING_ROWS_PERPAGE = DEFAULT_ROWS_PERPAGE - visibleRows?.length;

  const tempName = moduleNameMap(module);

  const headCells =
    emailFields.length > 0
      ? [{ id: tempName, label: "Name" }, ...emailFields]?.map((el) => {
          if (el.id === tempName) {
            return el;
          }
          return {
            id: el?.api_name,
            label: el?.display_label,
          };
        })
      : [];
  return (
    <Box
      sx={{
        p: "0 1px 1px 1px",
        height: tablePlaceholderHeight,
        overflow: "auto",
        display: "flex",
        alignItems: "stretch",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          bgcolor: "red--",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TableContainer sx={{ flexGrow: 1 }}>
          <MUITable
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            {/* setSelected */}
            <EnhancedTableHead
              onSelectAllClick={handleSelectAllClick}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
            />
            <TableBody>
              {rowstaLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={headCells?.length + 1}
                    sx={{ textAlign: "center", borderBottom: "none" }}
                  >
                    <CircularProgress
                      sx={{ mx: "auto", position: "relative", top: "100px" }}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
              {rows === null || rows?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headCells?.length + 1}
                    sx={{ textAlign: "center", borderBottom: "none" }}
                  >
                    <Box
                      sx={{
                        mx: "auto",
                        position: "relative",
                        top: "100px",
                      }}
                    >
                      No data
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
              {rows ? (
                <>
                  {visibleRows?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected(row.id)}
                        tabIndex={-1}
                        key={row.id}
                        selected={isSelected(row.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell sx={{}} padding="none">
                          <Checkbox
                            onClick={(e) => {
                              handleSelectRow(row.id);
                            }}
                            disabled={isDisabled(row.id)}
                            color="primary"
                            checked={isSelected(row.id)}
                            inputProps={{
                              "aria-labelledby": `enhanced-table-checkbox-${index}`,
                            }}
                            sx={{ py: "6px", pl: "9px" }}
                          />
                        </TableCell>
                        {headCells?.map((el) => {
                          return (
                            <TableCell key={el.id} padding="none">
                              <Box as="span" sx={ellipsisStyle}>
                                {row[el.id]}
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              ) : null}
              {REMAINING_ROWS_PERPAGE > 0 ? (
                <>
                  {Array.from({ length: REMAINING_ROWS_PERPAGE })?.map(
                    (row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            padding="none"
                            colSpan={headCells?.length + 1}
                          >
                            <Checkbox
                              disabled={true}
                              color="primary"
                              inputProps={{
                                "aria-labelledby": `placeholder table row`,
                              }}
                              sx={{ py: "6px", pl: "9px" }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </>
              ) : null}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Box
          sx={{
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            display: "flex",
            justifyContent: "space-between",
            height: "fit-content",
          }}
        >
          <TablePagination
            sx={{ width: "fit-content" }}
            rowsPerPageOptions={[DEFAULT_ROWS_PERPAGE, 50, 100]}
            component="div"
            count={rows?.length > 0 ? rows?.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box
            // sx={{
            //   bgcolor: "red",
            //   display: "grid",
            //   gap: ".5em",
            //   gridTemplateColumn: "100px 100px",
            // }}
            sx={{ display: "flex", alignItems: "center", pr: "24px" }}
          >
            <Box>
              <Button
                sx={{
                  fontSize: "medium",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  py: "9px",
                  // px: "10px",
                  mr: ".5em",
                  height: "100%",
                }}
                variant="outlined"
                disableRipple
                onClick={handleTableBack}
                disabled={listViewPage}
              >
                <ArrowBackIcon fontSize="inherit" />
              </Button>
              <Button
                sx={{
                  fontSize: "medium",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  py: 0.4,
                  // px: "10px",
                  mr: ".5em",
                }}
                variant="outlined"
                color="error"
                onClick={async () => {
                  window.ZOHO.CRM.UI.Popup.close().then(function (data) {
                    console.log(data);
                  });
                }}
              >
                Close
              </Button>
              <Button
                disabled={selected?.length < 1}
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                onClick={onTableClose}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
