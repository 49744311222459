import { useState, useEffect } from "react";
import { zohoApi } from "../zohoApi";

export const useZohoAuth = ({ height, width, checkZohoAuth = false } = {}) => {
  const [authLoadingZoho, setAuthLoadingZoho] = useState();
  const [zohoAuthenticated, setZohoAuthenticated] = useState();
  const [apiKey, setApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState();
  const [zuid, setZuid] = useState(null);
  const [initZoho, setInitZoho] = useState();
  const [accessToken, setAccessToken] = useState();
  const [initialData, setInitialData] = useState();

  useEffect(() => {
    setAuthLoadingZoho(true);
    zohoApi.auth.initZohoAuth(
      (data, error) => {
        const {
          zohoAuthenticated,
          orgId,
          apiKey,
          zapiKey,
          dataCenterUrl,
          zuid,
          initialData,
          accessToken,
        } = data;
        setZohoAuthenticated(zohoAuthenticated);
        setOrgId(orgId);
        setApiKey(apiKey);
        setZapiKey(zapiKey);
        setDataCenterUrl(dataCenterUrl);
        setZuid(zuid);
        setAuthLoadingZoho(false);
        setInitialData(initialData);
        setAccessToken(accessToken);
      },
      { height, width },
      (initZoho) => {
        setInitZoho(initZoho);
      },
      checkZohoAuth
    );
  }, [height, width, checkZohoAuth]);

  const handleRevokeAuth = async () => {
    if (orgId && apiKey) {
      setAuthLoadingZoho(true);
      const revokeResp = await zohoApi.auth.handleRevokeZoho({
        orgId,
        apiKey,
      });
      setZohoAuthenticated(revokeResp?.zohoAuthenticated);
      setAuthLoadingZoho(false);
    }
  };

  const handleGrantAuth = async () => {
    if (orgId && apiKey && zapiKey && dataCenterUrl && zuid) {
      setAuthLoadingZoho(true);
      zohoApi.auth.handleAuthenticateZoho(
        {
          orgId,
          apiKey,
          zapiKey,
          dataCenterUrl,
          zuid,
        },
        (data, error) => {
          setZohoAuthenticated(data?.zohoAuthenticated);
          setAuthLoadingZoho(false);
        }
      );
    }
  };

  return {
    orgId,
    apiKey,
    zapiKey,
    initialData,
    module: initialData?.Entity || initialData?.module,
    recordId: initialData?.EntityId,
    configdata: initialData?.configdata,
    buttonPosition: initialData?.ButtonPosition,
    initZoho,
    accessToken,
    dataCenterUrl,
    authLoadingZoho,
    zohoAuthenticated,
    handleGrantAuth,
    handleRevokeAuth,
  };
};
