import axios from "axios";
const ZOHO = window.ZOHO;

export const getConfigFromDB = async ({
  orgId,
  apiKey,
  dataCenterUrl,
  settingId,
}) => {
  try {
    let reqOptions = {
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings/${settingId}`,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        orgid: orgId,
        apikey: apiKey,
        datacenterurl: dataCenterUrl,
        connname: process.env.REACT_APP_EXTENSION_IDENTIFIER,
      },
    };
    const settingResp = await axios(reqOptions);
    return { data: settingResp?.data, error: null };
  } catch (saveConfigToDBError) {
    console.log({ saveConfigToDBError });
    // return { error: error?.response?.statusText,data:null };
    return {
      data: null,
      error: "Something went wrong",
    };
  }
};

export const upsertConfigToDB = async ({
  orgId,
  apiKey,
  dataCenterUrl,
  settingType,
  moduleApiName,
  settingId,
  settingSchema,
}) => {
  try {
    let reqOptions = {
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingType,
        moduleApiName,
        settingId,
        settingSchema,
      },
      headers: {
        orgid: orgId,
        apikey: apiKey,
        datacenterurl: dataCenterUrl,
        connname: process.env.REACT_APP_EXTENSION_IDENTIFIER,
      },
    };
    const folderResp = await axios(reqOptions);
    // console.log({ folderResp });
    return { data: folderResp?.data, error: null };
  } catch (saveConfigToDBError) {
    console.log({ saveConfigToDBError });
    // return { error: error?.response?.statusText };
    return {
      data: null,
      error: "Something went wrong",
    };
  }
};

export const saveConfigToZoho = async ({
  orgId,
  dataCenterUrl,
  settingType,
  moduleApiName,
  settingId,
}) => {
  try {
    const data = {
      connname: process.env.REACT_APP_EXTENSION_IDENTIFIER,
      orgid: orgId,
      datacenterurl: dataCenterUrl,
      settingType,
      moduleApiName,
      settingId,
    };
    const resp = await ZOHO.CRM.ACTION.setConfig(data);
    // console.log({ resp });
    return {
      data: resp,
      error: null,
    };
  } catch (saveConfigToZohoError) {
    console.log({ saveConfigToZohoError });
    return {
      data: null,
      error: "Something went wrong",
    };
  }
};

export const config = {
  getConfigFromDB,
  saveConfigToZoho,
  upsertConfigToDB,
};
