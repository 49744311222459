import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { EmailComponent } from "../components/templates/EmailComponent";
import { InitialPage } from "../components/organisms/InitialPage";
import { Dialog } from "../components/organisms/Dialog";
import { useZohoAuth } from "../hook/useZohoAuth";
import { zohoApi } from "../zohoApi";
import { editor, specialModules } from "../config/config";

const { useState, useEffect } = React;
const customActionPage = true;

export const CustomActionRoute = () => {
  const { module, accessToken, orgId, apiKey, configdata, dataCenterUrl } =
    useZohoAuth();
  const [relatedListModule, setRelatedListModule] = useState();
  const [openEmail, setOpenEmail] = useState(false);
  const [firstPage, setFirstPage] = useState();
  const [relatedListApis, setRelatedListApis] = useState([]);
  const [relatedListLoading, setRelatedListLoading] = useState();
  const [singleRecordDataLoading, setSingleRecordDataLoading] = useState();
  const [crmUsersEmail, setUsersCrmEmails] = useState([]);
  const [crmUsersEmailLoading, setCrmUsersEmailLoading] = useState(false);
  const [fromEmail, setFromEmail] = useState("");
  //-------------------
  const [subject, setSubject] = useState("");
  const [emailContent, setEmailContent] = React.useState("");
  const [editorType, setEditorType] = useState(editor.rich_text);
  //-------------------
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [emailTemplatesLoading, setEmailTemplatesLoading] = useState(false);
  //-------------------
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState();
  //-------------------
  const [inventoryTemplates, setInventoryTemplates] = useState([]);
  const [inventoryTemplatesLoading, setInventoryTemplatesLoading] =
    useState(false);
  //-------------------
  const [selectedInventoryTemplate, setSelectedInventoryTemplate] = useState(
    []
  );
  //-------------------
  const [selectedFallbackEmail, setSelectedFallbackEmail] = React.useState("");
  const [isOwnerEmailSelected, setIsOwnerEmailSelected] = React.useState(false);
  //-------------------
  const [savedModule, setSavedModule] = useState();
  //-------------------
  const [emailFields, setEmailFields] = useState([]);
  const [fileFields, setFileFields] = useState([]);
  const [initPageContent, setInitPageContent] = React.useState(
    <CircularProgress />
  );
  //-------------------
  const [configdataLoading, setConfigdataLoading] = useState(false);
  const configSchema = React.useRef();

  useEffect(() => {
    if (accessToken === null) {
      setInitPageContent(<h1>Not authenticated.</h1>);
    }
  }, [accessToken]);

  useEffect(() => {
    async function getCrmEmails({ accessToken, dataCenterUrl }) {
      setCrmUsersEmailLoading(true);
      const { data: resp } = await zohoApi.user.getCrmUsersEmail({
        accessToken,
        dataCenterUrl,
      });
      setUsersCrmEmails(resp);
      setFromEmail(resp?.[0]);
      setCrmUsersEmailLoading(false);
    }
    if (accessToken && dataCenterUrl) {
      getCrmEmails({ accessToken, dataCenterUrl });
    }
  }, [accessToken, dataCenterUrl]);

  useEffect(() => {
    const loadDbConfig =
      orgId &&
      apiKey &&
      dataCenterUrl &&
      module &&
      accessToken &&
      configdata?.settingId;

    const fetchConfig = async () => {
      const dbResp = await zohoApi.config.getConfigFromDB({
        orgId,
        apiKey,
        dataCenterUrl,
        settingId: configdata?.settingId,
      });

      const parsedDbResp = await JSON.parse(
        dbResp?.data?.data?.setting_schema || {}
      );
      // console.log({ parsedDbResp });
      configSchema.current = parsedDbResp;

      const activeModule =
        parsedDbResp?.relatedListModule || parsedDbResp?.module;

      await fetchEmailAndFileFields({
        module: activeModule,
        accessToken,
        dataCenterUrl,
      });

      await fetchEmailTemplates({
        module: activeModule,
        accessToken,
        dataCenterUrl,
      });

      if (specialModules.includes(activeModule)) {
        await fetchInventoryTemplates({
          module: activeModule,
          accessToken,
          dataCenterUrl,
        });
      }
      //---------
      setFromEmail(parsedDbResp?.fromEmail);
      setSubject(parsedDbResp?.subject);
      setEmailContent(parsedDbResp?.emailContent);
      setEditorType(editor.template);
      setSavedModule(parsedDbResp?.module);
      setRelatedListModule(parsedDbResp?.relatedListModule);
      setIsOwnerEmailSelected(parsedDbResp?.ownerEmailSelected);
      setSelectedFallbackEmail(parsedDbResp?.fallbackEmail);
      setSelectedEmailTemplateId(parsedDbResp?.selectedEmailTemplateId);
      setSelectedInventoryTemplate(parsedDbResp?.selectedInventoryTemplate);
      //---------
      setOpenEmail(true);
      setConfigdataLoading(false);
    };
    if (loadDbConfig) {
      setConfigdataLoading(true);
      fetchConfig();
    }
  }, [
    orgId,
    apiKey,
    dataCenterUrl,
    module,
    accessToken,
    configdata?.settingId,
  ]);

  const fetchRelatedListApis = async ({
    module,
    accessToken,
    dataCenterUrl,
  }) => {
    const { data: resp } = await zohoApi.record.getRelatedListApis({
      module,
      accessToken,
      dataCenterUrl,
    });
    setRelatedListApis(resp);
  };

  useEffect(() => {
    if (dataCenterUrl && module && accessToken && !configdata?.settingId) {
      setFirstPage(true);
      fetchRelatedListApis({ module, accessToken, dataCenterUrl });
    }
  }, [dataCenterUrl, module, accessToken, configdata?.settingId]);

  const fetchEmailAndFileFields = async ({
    module,
    accessToken,
    dataCenterUrl,
  }) => {
    const { data } = await zohoApi.record.getEmailAndFileFields({
      module,
      accessToken,
      dataCenterUrl,
    });
    const tempEmails = await data?.emailFields;
    setEmailFields(tempEmails);
    const fileFields = await data?.fileFields;
    setFileFields(fileFields);
    return tempEmails?.map((el) => el.api_name);
  };

  const fetchEmailTemplates = async ({
    module,
    accessToken,
    dataCenterUrl,
  }) => {
    setEmailTemplatesLoading(true);
    const { data: templates } = await zohoApi.email.getEmailTemplateList({
      module,
      accessToken,
      dataCenterUrl,
    });
    setEmailTemplates(templates);
    setEmailTemplatesLoading(false);
  };

  const fetchInventoryTemplates = async ({
    module,
    accessToken,
    dataCenterUrl,
  }) => {
    setInventoryTemplatesLoading(true);

    const { data: inventoryTemplates } =
      await zohoApi.inventory.getInventoryTemplateList({
        module,
        accessToken,
        dataCenterUrl,
      });

    if (inventoryTemplates?.length < 1) {
      setInitPageContent(<h1>No inventory template.</h1>);
      return;
    }

    setInventoryTemplates(inventoryTemplates);
    setInventoryTemplatesLoading(false);
  };

  const fetchData = async ({ module, accessToken, dataCenterUrl }) => {
    setSavedModule(module);
    const tempEmails = await fetchEmailAndFileFields({
      module,
      accessToken,
      dataCenterUrl,
    });
    if (tempEmails?.length === 0) {
      setFirstPage(false);
      setInitPageContent(<h1>No email fields in this module.</h1>);
      return;
    }
    await fetchEmailTemplates({ module, accessToken, dataCenterUrl });
    if (specialModules.includes(module)) {
      await fetchInventoryTemplates({ module, accessToken, dataCenterUrl });
    }
    setOpenEmail(true);
  };

  const onClickSingleRecord = async () => {
    if (module && accessToken && dataCenterUrl) {
      setSingleRecordDataLoading(true);
      setRelatedListModule(undefined);
      await fetchData({ module, accessToken, dataCenterUrl });
      setSingleRecordDataLoading(false);
    }
  };

  const onSelectAutocomplete = async (e, value) => {
    if (value && accessToken && dataCenterUrl) {
      setRelatedListLoading(true);
      const newModule = value?.module?.api_name;
      setRelatedListModule(newModule);
      await fetchData({
        module: newModule,
        accessToken,
        dataCenterUrl,
      });
      setRelatedListLoading(false);
    }
  };

  const handleEmailClose = () => {
    setOpenEmail(false);
    setFirstPage(true);
    setSavedModule(undefined);
    configSchema.current = null;
    if (relatedListApis?.length === 0) {
      fetchRelatedListApis({ module, accessToken, dataCenterUrl });
    }
  };
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          pt: "30px",
          pl: "30px",
          pr: "30px",
          bgcolor: "rgba(245, 245, 245, 1)",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {initPageContent}
          {configdataLoading ? "configdataLoading" : null}
        </Box>
      </Box>

      <Dialog dialogOpen={firstPage} handleDialogClose={() => {}}>
        <Box
          sx={{
            bgcolor: "rgba(245, 245, 245, 1)",
            height: "100vh",
            width: "93%",
            m: "0 auto",
            mb: "40px",
            display: "flex",
            alignItems: "center",
            borderRadius: "1em",
          }}
        >
          <InitialPage
            relatedListApis={relatedListApis}
            onClickSingleRecord={onClickSingleRecord}
            singleRecordDataLoading={singleRecordDataLoading}
            onSelectAutocomplete={onSelectAutocomplete}
            relatedListLoading={relatedListLoading}
            customActionPage={customActionPage}
          />
        </Box>
      </Dialog>

      <Dialog dialogOpen={openEmail} handleDialogClose={handleEmailClose}>
        <EmailComponent
          module={module}
          recordId={""}
          accessToken={accessToken}
          relatedListModule={relatedListModule}
          setRelatedListModule={setRelatedListModule}
          freeSolo={false}
          noParentRecord={true}
          handleEmailBack={handleEmailClose}
          //------
          crmUsersEmail={crmUsersEmail}
          crmUsersEmailLoading={crmUsersEmailLoading}
          //------
          selectedEmailTemplateId={selectedEmailTemplateId}
          setSelectedEmailTemplateId={setSelectedEmailTemplateId}
          //------
          fromEmail={fromEmail}
          setFromEmail={setFromEmail}
          //------
          emailTemplates={emailTemplates}
          emailTemplatesLoading={emailTemplatesLoading}
          //------
          inventoryTemplates={inventoryTemplates}
          inventoryTemplatesLoading={inventoryTemplatesLoading}
          selectedInventoryTemplate={selectedInventoryTemplate}
          setSelectedInventoryTemplate={setSelectedInventoryTemplate}
          //------
          selectedFallbackEmail={selectedFallbackEmail}
          setSelectedFallbackEmail={setSelectedFallbackEmail}
          isOwnerEmailSelected={isOwnerEmailSelected}
          setIsOwnerEmailSelected={setIsOwnerEmailSelected}
          //------
          storage={{}}
          emailOptions={emailFields}
          finalRecords={[]}
          inventoryModule={
            relatedListModule
              ? specialModules.includes(relatedListModule)
              : specialModules.includes(module)
          }
          dataCenterUrl={dataCenterUrl}
          //------
          subject={subject}
          setSubject={setSubject}
          emailContent={emailContent}
          setEmailContent={setEmailContent}
          editorType={editorType}
          setEditorType={setEditorType}
          //------
          savedModule={savedModule}
          customActionPage={customActionPage}
          orgId={orgId}
          apiKey={apiKey}
          configdata={configdata}
          configSchema={configSchema.current}
          setConfigdataLoading={setConfigdataLoading}
        />
      </Dialog>
    </>
  );
};
