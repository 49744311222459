import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import { validateEmail } from "../../util/util";

const textfieldStyle = {
  width: "100%",
  "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
    borderBottom: 0,
  },
  "& .MuiInput-root::before": {
    borderBottom: 0,
  },
  "& .MuiInput-root::after": {
    borderBottom: 0,
  },
};

export const MultiInputTextField = ({
  freeSolo,
  options,
  array,
  setArray,
  name,
  label,
  errors = {},
  handleClose,
  to,
}) => {
  const [values, setValues] = React.useState([]);
  const checkValidEmail = (event, values, reason) => {
    const lowerCaseValues = values.map((v) => v.toLowerCase());
    let newestInput = lowerCaseValues?.slice(-1)?.[0];
    let validEmail = validateEmail(newestInput);
    if (validEmail || lowerCaseValues?.length === 0) {
      setArray(lowerCaseValues);
      setValues(lowerCaseValues);
    }
    if (reason === "clear") {
      handleClose?.();
    }
  };
  const regular = (event, values, reason) => {
    setArray(values);
    setValues(values);
    if (reason === "clear") {
      handleClose?.();
    }
  };
  return (
    <Autocomplete
      id="tags-filled"
      sx={{
        width: "100%",
        "& .MuiAutocomplete-inputRoot": {
          pr: "0 !important",
        },
      }}
      slotProps={{
        popper: {
          sx: {
            "& div": {
              width: "fit-content",
            },
          },
        },
      }}
      size="small"
      popupIcon={""}
      value={array}
      // autoSelect={true}
      limitTags={5}
      multiple
      freeSolo={freeSolo}
      clearOnEscape
      clearOnBlur
      disableClearable={true}
      options={options}
      filterSelectedOptions
      onChange={freeSolo ? checkValidEmail : regular}
      onBlur={
        freeSolo
          ? (e) => {
              const newValue = e.target.value.toLowerCase();
              if (!values.includes(newValue)) {
                checkValidEmail(e, [...values, newValue]);
              }
            }
          : null
      }
      getOptionLabel={(option) => (freeSolo ? option : option?.display_label)}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            // key={option}
            size="small"
            variant="outlined"
            label={freeSolo ? option : option?.display_label}
            {...getTagProps({ index })}
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          // helperText={"errors"}
          // error={"!!errors.tags"}
          variant="standard"
          sx={textfieldStyle}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">{label}</InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            ...(!to && {
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <ClearIcon
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setArray([]);
                        handleClose();
                      }}
                    />
                  </InputAdornment>
                  {params.InputProps.endAdornment}
                </>
              ),
            }),
          }}
        />
      )}
    />
  );
};
