export const fixedLengthStr = (name, length = 17) => {
  let newLabel;
  if (name?.length > length) {
    const firstNch = name.slice(0, length - 9);
    const fileExtension = "." + name.split(".").slice(-1);
    const letterBeforeFileExtension = name.split(".").slice(-2)[0].slice(-1);
    newLabel = firstNch + "..." + letterBeforeFileExtension + fileExtension;
  } else {
    newLabel = name;
  }
  return newLabel;
};

export const validateEmail = (email) => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
};
