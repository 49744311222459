const ZOHO = window.ZOHO;

export async function getEmailTemplateList({
  module,
  accessToken,
  dataCenterUrl,
}) {
  try {
    const url = `${dataCenterUrl}/crm/v6/settings/email_templates`;
    var req_data = {
      params: { module },
      url,
      param_type: 1,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    const templateResp = await ZOHO.CRM.HTTP.get(req_data);

    if (templateResp === "") {
      return {
        data: [],
        error: null,
      };
    }

    const newTemplateResp = await JSON.parse(templateResp);
    return {
      data: newTemplateResp?.email_templates,
      error: null,
    };
  } catch (getEmailTemplateListError) {
    console.log({ getEmailTemplateListError });
    return {
      data: null,
      error: "something went wrong",
    };
  }
}

export async function getSingleEmailTemplate({
  templateId,
  accessToken,
  dataCenterUrl,
}) {
  try {
    const url = `${dataCenterUrl}/crm/v6/settings/email_templates/${templateId}`;
    var req_data2 = {
      url,
      param_type: 1,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    const templateResp2 = await ZOHO.CRM.HTTP.get(req_data2);
    const resp = await JSON.parse(templateResp2);

    return {
      data: resp?.email_templates?.[0],
      error: null,
    };
  } catch (getDataMultiselectLookupRestAPI) {
    console.log({ getDataMultiselectLookupRestAPI });
    return {
      data: null,
      error: "Something went wrong.",
    };
  }
}

export async function sendEmail({
  module,
  recordId,
  fromEmail,
  toArray,
  ccArray,
  bccArray,
  subject,
  content,
  attachments,
  inventory_template,
  accessToken,
  dataCenterUrl,
}) {
  const arrayToObjArray = (array) => array?.map((el) => ({ email: el }));
  try {
    const to = arrayToObjArray(toArray);
    const cc = arrayToObjArray(ccArray);
    const bcc = arrayToObjArray(bccArray);

    // const fromToCcBcc = {
    //   from: { email: "user1@demo3.instawebworks.com.au" },
    //   to: [
    //     {
    //       user_name: "RedwanIWW",
    //       email: "redwan@instawebworks.com.au",
    //     },
    //     {
    //       user_name: "RedwanGmail",
    //       email: "redwane19@gmail.com",
    //     },
    //   ],
    //   // cc: [
    //   //   {
    //   //     email: "emran@instawebworks.com.au",
    //   //   },
    //   //   {
    //   //     email: "ralph@wolq.nl",
    //   //   },
    //   // ],
    //   // bcc: [
    //   //   {
    //   //     email: "emran@instawebworks.com.au",
    //   //   },
    //   //   {
    //   //     email: "ralph@wolq.nl",
    //   //   },
    //   // ],
    //   subject,
    //   content,
    //   mail_format: "html",
    //   org_email: false,
    // };

    const fromToCcBcc = {
      from: { email: fromEmail },
      to,
      subject,
      content,
      mail_format: "html",
      org_email: true,
    };

    if (cc.length > 0) {
      fromToCcBcc.cc = cc;
    }

    if (bcc.length > 0) {
      fromToCcBcc.bcc = bcc;
    }

    if (attachments.length > 0) {
      fromToCcBcc.attachments = attachments?.map((str) => ({ id: str }));
    }
    console.log({ inventory_template });
    if (inventory_template.length > 0) {
      fromToCcBcc.inventory_details = {
        inventory_template: {
          id: inventory_template?.[0]?.id,
          name: inventory_template?.[0]?.name,
        },
      };
    }

    var req_data = {
      url: `${dataCenterUrl}/crm/v6/${module}/${recordId}/actions/send_mail`,
      body: JSON.stringify({
        data: [fromToCcBcc],
      }),
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    const mailResp = await ZOHO.CRM.HTTP.post(req_data);
    const resp = await JSON.parse(mailResp);
    console.log({ fromToCcBcc, resp });
    return {
      data: resp?.data?.[0],
      error: null,
    };
  } catch (sendMailFromZohoError) {
    console.log({ sendMailFromZohoError });
    return {
      message: "Email sent failed",
      status: "failed",
    };
  }
}

export const email = {
  getEmailTemplateList,
  getSingleEmailTemplate,
  sendEmail,
};
