import * as React from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Autocomplete,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";

const cardHeaderStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  mb: "1rem",
};

const autocompleteStyle = {
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: 1.75,
  letterSpacing: "0.02857em",
  borderRadius: "4px",
  width: "100%",
  // color: "white",
  // bgcolor: "rgba(25, 118, 210, 1)",
  // boxShadow:
  //   "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  // transition:
  //   "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  // "&:hover": {
  //   bgcolor: "#1565c0",
  //   boxShadow:
  //     "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
  //   "& .MuiOutlinedInput-notchedOutline": {
  //     border: "none",
  //   },
  // },
  // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //   border: "none",
  // },
  // "& fieldset": { border: "none" },
  // "& .MuiSvgIcon-root": {
  //   color: "white !important",
  // },
};

const Card = ({ title, icon, children, bgcolor, loading }) => {
  const cardContainerStyle = {
    // width: "280px",
    borderRadius: "16px",
    p: "1.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    outline: "1px solid rgba(0, 0, 0, 0.23)",
    flexGrow: 1,
    bgcolor: "rgba(255, 255, 255, 1)",
  };

  const cardIconStyle = {
    bgcolor,
    width: "52px",
    height: "52px",
    borderRadius: "50%",
    display: "grid",
    placeItems: "center",
    mb: ".5rem",
  };

  return (
    <Box sx={cardContainerStyle}>
      <Box sx={cardIconStyle}>{icon}</Box>
      <Typography sx={cardHeaderStyle}>{title}</Typography>
      <Box sx={{ width: "100%", position: "relative" }}>
        {children}
        {loading ? (
          <Box
            sx={{
              position: "absolute",
              inset: "3px",
              // bgcolor: "red",
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress size={17} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export const InitialPage = ({
  singleRecordDataLoading,
  relatedListLoading,
  onClickSingleRecord,
  relatedListApis,
  onSelectAutocomplete,
  blueprintPage,
  customActionPage,
}) => {
  return (
    <>
      {blueprintPage ? (
        <Box
          sx={{
            // pb: "30px",
            py: "14px",
            pl: "30px",
            pr: "20px",
            display: "flex",
            justifyContent: "space-between",

            // bgcolor: "red",
          }}
        >
          <Typography
            sx={{
              color: "#202123",
              fontSize: "20px",
              // height: "24px",
              fontWeight: 700,
              // lineHeight: 0.8,
            }}
          >
            Easy Email
          </Typography>
          <IconButton
            aria-label="close"
            color="primary"
            sx={{ p: 0 }}
            disableRipple
            onClick={async () => {
              window.ZOHO.CRM.UI.Popup.close().then(function (data) {
                console.log(data);
              });
            }}
          >
            <CloseIcon sx={{ color: "black", fontWeight: 400 }} />
          </IconButton>
        </Box>
      ) : null}
      <Box
        sx={{
          height: customActionPage ? null : "100vh",
          pt: "30px",
          pl: "30px",
          pr: "30px",
          bgcolor: "rgba(245, 245, 245, 1)",
        }}
      >
        <Box sx={{ mb: "1.5rem" }}>
          <Typography sx={{ ...cardHeaderStyle, mb: 0 }}>
            Select Email Source
          </Typography>
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Select the source for your email: you can either use the email to
            the current record or choose one from a related module to send email
            to Related Records.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            title="Email to Current Record"
            icon={<SummarizeOutlinedIcon />}
            bgcolor="rgba(225, 245, 254, 1)"
            loading={singleRecordDataLoading}
          >
            <Button
              sx={{ width: "100%" }}
              disabled={singleRecordDataLoading || relatedListLoading}
              variant="contained"
              disableRippleawait
              onClick={onClickSingleRecord}
            >
              <span
                style={{
                  color: singleRecordDataLoading ? "transparent" : "",
                }}
              >
                Next
              </span>
            </Button>
          </Card>
          <Card
            title=" Email To Related Records"
            icon={<FormatListBulletedOutlinedIcon />}
            bgcolor="rgba(255, 248, 225, 1)"
            loading={relatedListLoading}
          >
            <Autocomplete
              sx={autocompleteStyle}
              disabled={relatedListLoading || singleRecordDataLoading}
              disablePortal
              options={relatedListApis}
              size="small"
              onChange={onSelectAutocomplete}
              value={""}
              getOptionLabel={(option) => option.display_label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  loading={true}
                  placeholder="Choose a Related Module"
                  sx={{ "& input::placeholder": { opacity: 0.9 } }}
                />
              )}
            />
          </Card>
        </Box>
      </Box>
    </>
  );
};
